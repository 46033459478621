/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-21 14:03:39
 * @LastEditors: Arvin
 * @LastEditTime: 2020-12-24 14:23:26
 */
import { numberFormat, wGetTextLength } from '@/plugins/utils'
export default {
  name: 'TransformList',
  props: {
    source: {
      type: Array,
      default: () => ([])
    },
    value: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      selected: []
    }
  },
  watch: {
    selected (newValue) {
      // console.log('selected:' + newValue)
      this.$emit('input', newValue)
    },
    value (newValue) {
      this.selected = newValue
    }
  },
  mounted () {
    this.selected = this.value
  },
  methods: {
    numberFormat,
    wGetTextLength
  }
}

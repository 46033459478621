var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('w-transfer-list',{attrs:{"filterable":"","filter-placeholder":"请输入关键字","data":_vm.source},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('div',{},[_c('el-row',{staticStyle:{"font-size":"14px","font-weight":"400","color":"#13234E"}},[_c('el-col',{attrs:{"span":16}},[_c('el-tooltip',{attrs:{"effect":"dark","placement":"top","disabled":_vm.wGetTextLength(node.label) < 26,"content":node.label}},[_c('span',{staticClass:"tooltip-inner__span"},[_vm._v(_vm._s(node.label))])])],1),_c('el-col',{staticStyle:{"text-align":"right"},attrs:{"span":8}},[_vm._v(_vm._s(_vm.numberFormat(data.number - 0)))])],1)],1)}},{key:"result-item",fn:function(ref){
var node = ref.node;
var data = ref.data;
var eventHandle = ref.eventHandle;
return _c('div',{},[_c('el-row',{staticStyle:{"font-size":"14px","font-weight":"400","color":"#13234E"}},[_c('el-col',{attrs:{"span":16}},[_c('el-tooltip',{attrs:{"effect":"dark","placement":"top","disabled":_vm.wGetTextLength(node.label) < 26,"content":node.label}},[_c('span',{staticClass:"tooltip-inner__span"},[_vm._v(_vm._s(node.label))])])],1),_c('el-col',{staticStyle:{"text-align":"right"},attrs:{"span":8}},[_vm._v(" "+_vm._s(_vm.numberFormat(data.number - 0))+" "),_c('span',{staticClass:"ab_font_close icon-remove",on:{"click":function($event){$event.stopPropagation();return (function () { return eventHandle({type: 'remove', data: data}); }).apply(null, arguments)}}})])],1)],1)}},{key:"result-footer-top",fn:function(ref){
var data = ref.data;
return _c('div',{},[(data.length > 0)?_c('span',[_vm._v("共 "+_vm._s(data.reduce(function (res, ref) {
var number = ref.number;
 res -= 0; res += number - 0; return res }, 0))+" 条数据(创建完成后将进行排重)")]):_vm._e()])}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('p',{staticClass:"w-transfer__header",attrs:{"slot":"title-left"},slot:"title-left"},[_vm._v("可选活动单元")]),_c('p',{staticClass:"w-transfer__header",attrs:{"slot":"title-right"},slot:"title-right"},[_vm._v("已选活动单元")]),_c('div',{attrs:{"slot":"item-title-left"},slot:"item-title-left"},[_vm._v("活动单元")]),_c('div',{attrs:{"slot":"item-title-right"},slot:"item-title-right"},[_vm._v("数据量")]),_c('div',{attrs:{"slot":"result-item-title-left"},slot:"result-item-title-left"},[_vm._v("活动单元")]),_c('div',{attrs:{"slot":"result-item-title-right"},slot:"result-item-title-right"},[_vm._v("数据量")])])}
var staticRenderFns = []

export { render, staticRenderFns }
/* eslint-disable quote-props */
/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-20 20:26:32
 * @LastEditors: Fengshi
 * @LastEditTime: 2020-11-27 15:02:18
 */
import { mapGetters as mapRootGetters, createNamespacedHelpers } from 'vuex'
import TransformList from './components/transformList'

const { mapGetters, mapActions } = createNamespacedHelpers('App/Datapackage/AddDiypackage')

export default {
  name: 'AddDiypackage',
  components: {
    TransformList
  },
  data () {
    return {
      rules: {
        name: [
          {
            required: true,
            message: '请输入人群包名称',
            trigger: ['blur']
          },
          {
            max: 50,
            message: '人群包名称不得超过50个字',
            trigger: ['blur']
          }
        ],
        source_type: [
          {
            required: true,
            message: '请选择新建方式',
            trigger: ['blur']
          }
        ],
        compose_type: [
          {
            validator (rule, val, res) {
              if (val.length === 0) {
                res('请选择数据组合关系')
                return
              }
              res()
            },
            required: true,
            message: '请选择数据组合关系',
            trigger: ['blur']
          }
        ],
        data_type_list: [
          {
            validator (rule, val, res) {
              if (val.length === 0) {
                res('请选择数据类型')
                return
              }
              res()
            },
            required: true,
            message: '请选择数据类型',
            trigger: ['blur']
          }
        ],
        active_list: [
          {
            validator (rule, val, res) {
              if (val.length === 0) {
                res('请选择数据来源')
                return
              }
              res()
            },
            required: true,
            message: '请选择数据来源',
            trigger: []
          }
        ],
        remove_list: [
          {
            validator (rule, val, res) {
              if (val.length === 0) {
                res('请选择排除数据')
                return
              }
              res()
            },
            required: true,
            message: '请选择排除数据',
            trigger: []
          }
        ]
      },
      typeOptions: [
        {
          name: '创建',
          id: 1,
          disabled: false
        }
      ],
      transformTypesOptions: [],
      activeListNoValidate: false,
      removeListNoValidate: false
    }
  },
  computed: {

    ...mapRootGetters([
      'enum_map'
    ]),

    ...mapGetters([
      'form',
      'activitySource'
    ]),

    /**
     * 数据组合关系选项
     */
    sourceOptions () {
      return this.enum_map.package_diy_source
    }

    /**
     * 数据类型选项
     */
    // transformTypesOptions: {
    //   get () {
    //     return this.enum_map.diy_data_type_enums.map(({ id, name }) => ({ id, name, selected: false, disabled: false }))
    //   }
    //   set (val) {

    //   }
    // }

  },
  watch: {
    'form.active_list' (newVal) {
      if (this.activeListNoValidate) {
        this.activeListNoValidate = false
        return
      }
      this.validateField('active_list')
    },
    'form.remove_list' () {
      if (this.removeListNoValidate) {
        this.removeListNoValidate = false
        return
      }
      this.validateField('remove_list')
    }
  },
  async mounted () {
    this.transformTypesOptions = [{
      name: '不限',
      id: 0,
      disabled: false,
      selcted: false
    }].concat(this.enum_map.package_data_type.map(({ id, name }) => ({ id, name, selected: false, disabled: false })))
    // 获取所有的活动列表
    this.getAllActivities()
  },
  beforeDestroy () {
    this.resetForm()
  },
  methods: {

    ...mapActions([
      'getAllActivities',
      'updateForm', // 更新表单
      'addPackageSubmit',
      'resetForm'
    ]),

    /**
     * 新建方式
     */
    sourceTyppeChange () {
      this.validateField('source_type')
    },

    /**
     * 数据类型选择改变
     */
    async transformTypesChange (vals) {
      const lastVal = vals.concat().pop()
      let newVal
      if (lastVal === 0) {
        newVal = this.transformTypesOptions.map(({ id, name }) => {
          return {
            id,
            name,
            selected: id - 0 === 0,
            disabled: id - 0 > 0
          }
        })
        vals = [0]
      } else {
        vals = vals.filter(i => i > 0)
        newVal = this.transformTypesOptions.map(({ id, name }) => {
          return {
            id,
            name,
            selected: vals.includes(id),
            disabled: id - 0 === 0
          }
        })
      }
      this.activeListNoValidate = true
      this.removeListNoValidate = true
      this.transformTypesOptions = newVal
      await this.updateForm({ data_type_list: vals, active_list: [], remove_list: [] })
      this.validateField('data_type_list')
      this.getAllActivities()
    },

    /**
     * 组合类型切换
     */
    composeTypeChange (val) {
      this.form.active_list = []
      console.log(val)
      switch (val) {
        case 1:
        case 2:
          break
        case 3:
          this.updateForm({ remove_list: [] })
          break
        default:
          break
      }
      this.validateField('compose_type')
    },

    /**
     * 验证某一项
     */
    async validateField (attr) {
      const formEle = this.$refs.form
      const valid = await new Promise(resolve => formEle.validateField(attr, resolve))
      console.log(valid)
      return valid
    },

    /**
     * 验证
     */
    async validate () {
      const formEle = this.$refs.form
      const valid = await new Promise(resolve => formEle.validate(resolve))
      return valid
    },

    /**
     * 提交新建人群包
     */
    async addPackage () {
      const valid = await this.validate()
      if (!valid) {
        this.$refs.submitButton.updatedLoading(false)
        return
      }
      console.log(this.form)
      const data = await this.addPackageSubmit(this.form)
      this.$refs.submitButton.updatedLoading(false)

      if (data === true) {
        this.$wToast.success('创建成功')
        this.resetForm()
        this.$router.go(-1)
      }
    },

    /**
     * 取消
     */
    cancel () {
      this.$router.go(-1)
    }
  }
}
